import React from "react";
import "../../project.css"
// import image from "./image1.png";
import { useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";

import img1 from './assets/img1.png'
import img2 from './assets/img2.png'
import img3 from './assets/img3.png'
import img4 from './assets/img4.png'
import img5 from './assets/img5.png'
import img6 from './assets/img6.png'
import img7 from './assets/img7.png'
import img8 from './assets/img8.png'
import img9 from './assets/img9.png'
import img10 from './assets/img10.png'
import img11 from './assets/img11.png'
import img12 from './assets/img12.png'
import img13 from './assets/img13.png'
import img14 from './assets/img14.png'


const Comics = () => {
    const location = useLocation();
    useEffect(() => {
        // import { useEffect } from "react";
        // import { NavLink , useLocation } from "react-router-dom";
        const head = document.querySelector('header');
        const footer = document.querySelector('footer');
        if ((location.pathname.includes('/blog'))) {
            document.body.style.color = 'white';
            document.body.style.backgroundColor = 'black';
            head.style.color = "white";
            footer.style.color = "white";
            if (window.innerWidth < 768) {
                head.style.backgroundColor = "black"
                footer.style.backgroundColor = "black"
            }
            else {
                head.style.backgroundColor = "transparent"
                footer.style.backgroundColor = "transparent"
            }
        } else {
            // Reset to default styles if not on the /about route
            document.body.style.color = 'black';
            document.body.style.backgroundColor = 'white';
            head.style.color = "black";
            footer.style.color = "black";
            if (window.innerWidth < 768) {
                head.style.backgroundColor = "white"
                footer.style.backgroundColor = "white"
            }
            else {
                head.style.backgroundColor = "transparent"
                footer.style.backgroundColor = "transparent"
            }
        }
    }, [location.pathname]);
    return (
        <div className="overall project-overall blog">
            <div className="blog text">
                <div className="title">
                    <h1>Miscellaneous Comics</h1>
                    <div className="description">
                        <p>2020-2021</p>
                    </div>
                </div>
                <div className="right-text">
                    <p>
                        I run a comic page on instagram <a href="https://www.instagram.com/cynic_station/" target="_blank" className="link">(@cynic_station)</a>, this is a compilation of some of my favourites from the past few months.
                    </p>
                    <p>
                        Enjoy.
                    </p>
                </div>
                <div className="right-text">
                    <div className="grid-2-col">
                        <img src={img1} alt="" />
                        <img src={img2} alt="" />
                        <img src={img3} alt="" />
                        <img src={img4} alt="" />
                    </div>
                    <br />
                    <p className="text-center">World Polar Bear Day 2020</p>
                </div>
                <div className="right-text">
                    <img src={img5} alt="" className="square-image" />
                    <br />
                    <p className="text-center">Sea Shanties</p>
                </div>
                <div className="right-text">
                    <div className="grid-2-col">
                        <img src={img6} alt="" />
                        <img src={img7} alt="" />
                    </div>
                    <br />
                    <p className="text-center">Chai Break
                    </p>
                </div>
                <div className="right-text">
                    <img src={img8} alt="" className="square-image" />
                    <br />
                    <p className="text-center">Campus Monitor Lizard <br />
                        (Context- A funky-looking monitor lizard was spotted on campus multiple times- with students being at home due to the pandemic, the lizard is probably bored)</p>
                </div>
                <div className="right-text">
                    <img src={img9} alt="" className="square-image" />
                    <br />
                    <p className="text-center">Blue-footed Booby</p>
                </div>

                <div className="right-text">
                    <img src={img10} alt="" className="square-image" />
                    <br />
                    <p className="text-center">Productivity</p>
                </div>

                <div className="right-text">
                    <img src={img11} alt="" className="square-image" />
                    <br />
                    <p className="text-center">Primordial Soup/Celestial Beings </p>
                </div>

                <div className="right-text">
                    <img src={img12} alt="" className="square-image" />
                    <br />
                    <p className="text-center">Same.</p>
                </div>

                <div className="right-text">
                    <img src={img13} alt="" className="square-image" />
                    <br />
                    <p className="text-center">Marinate/Ruminate</p>
                </div>

                <div className="right-text">
                    <img src={img14} alt="" className="square-image" />
                    <br />
                    <p className="text-center">Subtitles</p>
                </div>

            </div>
            <div className="navigation">
                <NavLink to="/blog/garlic">
                    <FaArrowLeft />
                </NavLink>
                <NavLink to="/blog/pitbull">
                    <FaArrowRight />
                </NavLink>
            </div>
        </div>
    );
};

export default Comics;
