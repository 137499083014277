import React from "react";
import "../project.css";
import img1 from "./assets/w1.png";
import img2 from "./assets/w2.png"
import img3 from "./assets/w3.png"
import img4 from "./assets/w4.png"
import img5 from "./assets/w5.png"
import { NavLink } from "react-router-dom";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";
const Wlbs = () => {
  return (
    <div className="overall project-overall">
      <div className="text">
        <div className="title">
          <h1>WLBS</h1>
          <div className="description">
            <h2>Brand identity transformation for an innovative tech consultancy</h2>
            <p>Branding + Social Media</p>
          </div>
        </div>
        <div className="image">
          <img src={img1} alt="design" />
        </div>
        <div className="right-text">
          <p>
            WLBS, a tech consultancy founded in 2022, is dedicated to transforming digital aspirations into reality. Specialising in building websites, apps, and tech products for various sectors, WLBS showcases a successful track record marked by transparency and quality. Their solutions consistently surpass expectations, propelling clients confidently into the digital marketplace.
          </p>
        </div>
        <div className="image-large">
          <img src={img2} alt="design" />
        </div>
        <div className="right-text">
          <p>
          The WLBS logotype features sleek, modern lettering with clean lines and flowing connections between some letters, exuding a contemporary aesthetic. The primary font, Rethink Sans from Google Fonts, is a refined sans-serif chosen for its professionalism and sophistication, ensuring legibility across digital platforms. The secondary font, IBM Plex Serif Light, is reserved for print applications and minimal digital use, such as subtitles on blog pages.
          </p>
        </div>
        {/* <div className="image-large">
          <img src={img3} alt="design" />
        </div> */}
        <div className="image-large">
          <img src={img4} alt="design" />
        </div>
        <div className="right-text">
          <p>
          WLBS’s visual identity balances premium design with simplicity and clarity, conveying trustworthiness, innovation, and sophistication. Their professional and credible voice reflects client-centricity and deep industry knowledge, leading with insights and trends. Brand communication is engaging, avoiding overuse of technical jargon and casual slang.
          </p>
        </div>
        <div className="image-large">
          <img src={img5} alt="design" />
        </div>
        <div className="right-text">
          <p>
          The tagline "We Build For ____" underscores WLBS's customer-centric approach and reliability, adapting dynamically to emphasise their commitment to client success. 
          <br></br> <br></br>Team@ WLBS:<br></br>Anshul Laikar <br></br>Yash Mhatre
          </p>
        </div>
      </div>
      <div className="navigation">
        <NavLink to="/maaher">
          <FaArrowLeft  />
        </NavLink>
        <NavLink to="/pilolo">
          <FaArrowRight />
        </NavLink>
      </div>
    </div>
  );
};

export default Wlbs;
