import React from "react";
import "../../BlogPage/blog.css";
import image1 from "./1.png";
import image2 from "./2.png";
import image3 from "./3.png";
import image4 from "./4.png";
import image5 from "./5.png";
import image6 from "./6.png";
import image7 from "./7.png";
import image8 from "./8.png";
import image9 from "./9.png";
import { useEffect } from "react";
import { NavLink , useLocation } from "react-router-dom";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";

const Nine36 = () => {
  const location = useLocation();
  useEffect(() => {
    // import { useEffect } from "react";
    // import { NavLink , useLocation } from "react-router-dom";
    const head = document.querySelector('header');
    const footer = document.querySelector('footer');
    if ((location.pathname.includes('/blog'))) {
        document.body.style.color = 'white';
        document.body.style.backgroundColor = 'black';
        head.style.color = "white";
        footer.style.color = "white";
        if (window.innerWidth < 768) {
          head.style.backgroundColor = "black"
          footer.style.backgroundColor = "black"
        }
        else {
          head.style.backgroundColor = "transparent"
          footer.style.backgroundColor = "transparent"
        }
    } else {
        // Reset to default styles if not on the /about route
        document.body.style.color = 'black';
        document.body.style.backgroundColor = 'white';
        head.style.color = "black";
        footer.style.color = "black";
        if (window.innerWidth < 768) {
          head.style.backgroundColor = "white"
          footer.style.backgroundColor = "white"
        }
        else {
          head.style.backgroundColor = "transparent"
          footer.style.backgroundColor = "transparent"
        }
    }
}, [location.pathname]);

  return (
    <div className="overall project-overall blog">
      <div className="blog text">
        <div className="title">
          <h1>9 out of 36 Days of Type</h1>
          <div className="description">
            <h2>All the cool graphic design kids on social media were doing it. </h2>
            <p>2020</p>
          </div>
        </div>
        <div className="right-text">
          <p>
          I decided to use distinct themes for the alphabets and numbers, trying my hand at Illustration on Photoshop and Adobe Dimension respectively. <br></br><br></br>(#7 got featured on the official 36daysoftype page!!).       </p>
        </div>
        <div className=" grid grid-2-col-mob grid-3-col">
          <img src={image1} alt="design" />
          <img src={image2} alt="design" />
          <img src={image3} alt="design" />
          <img src={image4} alt="design" />
          <img src={image5} alt="design" />
          <img src={image6} alt="design" />
          <img src={image7} alt="design" />
          <img src={image8} alt="design" />
          <img src={image9} alt="design" />
        </div>
      </div>
      <div className="navigation">
        <NavLink to="/blog/pitbull">
          <FaArrowLeft />
        </NavLink>
        <NavLink to="/blog/cohen">
          <FaArrowRight />
        </NavLink>
      </div>
    </div>
  );
};

export default Nine36;
